<template>
    <div>
      <v-select
        ref="input"
        v-model="situacao"
        item-text="nome"
        item-value="id"
        @input="$emit('input', situacao)"
        :items="situacoes"
        :label="$i18n.t('Situacao')"
        dense
        clearable>
      </v-select>
    </div>
</template>
<script>
export default {
    props: ['value', 'empresa'],
    data() {
        return {
          situacao: this.value,
          situacoes: []
        }
    },
    watch: {
      empresa(n, o) {
        if (n != o)
            this.load();
      }
    },
    methods: {
      load() {
        let id = this.empresa ? (this.empresa.id || this.empresa) : 'me';
        this.$http.get(`/api/doc/situacoes/options/${id}`)
          .then((req) => {
              this.situacoes = req.data;
          })
          .catch((e) => {
            this.$msg.erro(e.response.data.message);
          });
      },
      reload(empresa) {
        this.empresa = empresa;
        this.load();
      },
      focus() {
        this.$nextTick(() => {
          if (this.$refs.input)
            this.$refs.input.focus()
        });
      }
    },
    created() {
      this.load();
    }
}
</script>
<style scoped lang="scss">
  .code-dialog {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    background: white;
    text-align: center;
    padding: 14px;

    .tools {
      width: 100%;
    }
  }
</style>