<template>  
  <div>
    <v-card>
      <v-card-title class="text-center justify-center">
        <h3 class="font-weight-bold basil--text">
          {{$i18n.t('OcorrenciasModelo')}}
        </h3>
      </v-card-title>

      <v-card-text>
        <v-row v-if="loading">
          <v-col cols="12" sm="12" align="center">
            <v-progress-circular
              slot="append"
              indeterminate
              color="primary">
            </v-progress-circular>
          </v-col>
        </v-row>

        <v-row v-if="!loading">
          <v-col cols="12" sm="12" align="right">

            <v-btn
              @click="removendo = true"
              color="red"
              dark
              v-if="modelo.id && perfil == 'ADMIN'">
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>

            <v-btn
              @click="lista"
              v-if="perfil == 'ADMIN'">
              <v-icon>
                mdi-view-headline
              </v-icon>
            </v-btn>

          </v-col>
        </v-row>

        <v-form
          ref="form"
          v-model="valido"
          v-if="!loading"
          lazy-validation>

          <v-row v-if="perfil == 'ADMIN'">
            <v-col cols="12" sm="12">
              <v-select
                v-model="modelo.empresa"
                :rules="requiredRules"
                item-text="nome"
                item-value="id"
                :items="empresas"
                :label="$i18n.t('Transportador')"
                required
                dense>
              </v-select>
            </v-col>
         </v-row>

          <v-row>
            <v-col cols="12" sm="12">
              <select-ocorrencia
                ref="ocorrencia"
                v-model="modelo.ocorrencia"
                :empresa="modelo.empresa">
              </select-ocorrencia>
              <!--
              <v-select
                ref="ocorrencia"
                v-model="modelo.ocorrencia"
                item-text="nome"
                item-value="id"
                :items="ocorrencias"
                :label="$i18n.t('TipoOcorrencia')"
                dense>
              </v-select>
              -->
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12">
              <select-situacao
                v-model="modelo.situacao"
                :empresa="modelo.empresa">
              </select-situacao>
              <!--
              <v-select
                v-model="modelo.situacao"
                item-text="nome"
                item-value="id"
                :items="situacoes"
                :label="$i18n.t('Situacao')"
                dense>
              </v-select>
              -->
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="modelo.data"
                :label="$i18n.t('Data')"
                v-mask="'##/##/####'">
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="2">
              <v-checkbox v-model="modelo.hoje" :label="$i18n.t('DataDoDia')"></v-checkbox>
            </v-col>
          
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="modelo.hora"
                :label="$i18n.t('Hora')"
                v-mask="'##:##:##'">
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="2">
              <v-checkbox v-model="modelo.agora" :label="$i18n.t('HoraDoLancamento')"></v-checkbox>
            </v-col>
            
          </v-row>

          <v-row>
            <v-col cols="12" sm="12">
              <v-textarea
                ref="descricao"
                v-model="modelo.descricao"
                :label="$i18n.t('DescricaoOcorrencia')">
              </v-textarea>
            </v-col>
          </v-row>

        </v-form>

        <div class="w100" v-if="!loading">
          <v-row>
            <v-col align="center" cols="12">
              <v-btn
                color="success"
                elevation="2"
                @click="salva"
                :disabled="!validateForm">
                <v-icon>
                  mdi-check
                </v-icon>
                {{$i18n.t('Salvar')}}
              </v-btn>
            </v-col>
          </v-row>
        </div>

      </v-card-text>
    </v-card>

    <v-dialog
      v-model="removendo"
      persistent
      max-width="290">

      <v-card v-if="perfil == 'ADMIN'">
        <v-card-title class="text-h5">
          {{$i18n.t('RemoveOcorrenciaModelo')}}
        </v-card-title>
        <v-card-text>{{$i18n.t('RemoveOcorrenciaModeloConfirmacao')}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="removendo = false">
            {{$i18n.t('Nao')}}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="remove">
            {{$i18n.t('Sim')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SelectOcorrencia from '@/components/SelectOcorrencia.vue';
import SelectSituacao from '@/components/SelectSituacao.vue';
export default {
    components: { SelectOcorrencia, SelectSituacao },
    data: () => ({
      id: null,
      loading: false,
      perfil: '',
      modelo: {},
      valido: false,
      requiredRules: [
        v => !!v
      ],
      removendo: false,
      empresas: []
    }),
    computed: {
      validateForm() {
        return this.modelo
          && this.modelo.nome != ''
          && this.modelo.ordem != '';
      }
    },
    methods: {
      load() {
        this.loading = true;
        this.$http.get(`/api/doc/ocorrencia/modelos/${this.id}`)
            .then((req) => {
              if (req.data.sucesso) {
                this.modelo = req.data.objeto;
                if (this.modelo == null)
                  this.$router.push('/error-403');
              }
              else
                 this.$msg.erro(req.data.mensagem);
            })
            .catch((e) => {
              this.$msg.erro(e.response.data.message);
            })
            .finally(() => {
              this.loading = false;
            });
      },
      loadTransportador() {
        this.$http.get('/api/ide/empresas')
          .then((req) => {
              this.empresas = req.data;
          })
          .catch((e) => {
            this.$msg.erro(e.response.data.message);
          });
      },
      lista() {
        this.$router.push('/ocorrencias/modelos');
      },
      remove() {
        this.removendo = false;
        this.loading = true;
        this.$http.delete(`/api/doc/ocorrencia/modelos/${this.id}`)
          .then((req) => {
            if (req.data.sucesso) {
              this.modelo = {ordem: 1};
              this.$msg.sucesso(this.$i18n.t('Sucesso'));
            } else
              this.$msg.erro(req.data.mensagem);
          })
          .catch((e) => {
            this.$msg.erro(e.response.data.message);
          })
          .finally(() => {
            this.loading = false;
            this.lista();
          });
      },
      salva() {
        
        if (this.$refs.form.validate()) {
          this.loading = true;

          let data = {
            id: this.modelo.id,
            ocorrencia: this.modelo.ocorrencia ? (this.modelo.ocorrencia.id || this.modelo.ocorrencia) : null,
            situacao: this.modelo.situacao ? (this.modelo.situacao.id || this.modelo.situacao) : null,
            data: this.modelo.data,
            hoje: this.modelo.hoje,
            hora: this.modelo.hora,
            agora: this.modelo.agora,
            descricao: this.modelo.descricao
          };

          if (this.perfil == 'ADMIN')
            data.empresa = this.modelo.empresa.id || this.modelo.empresa;

          this.$http.post('/api/doc/ocorrencia/modelos', data)
              .then((req) => {
                if (req.data.sucesso) {
                  this.modelo = req.data.objeto;
                  this.$msg.sucesso(this.$i18n.t('Sucesso'));
                } else
                  this.$msg.erro(req.data.mensagem);
              })
              .catch((e) => {
                this.$msg.erro(e.response.data.message);
              })
              .finally(() => {
                this.loading = false;
                this.focus();
              });
        } else {
          this.focus();
        }
      },
      focus() {
        this.$nextTick(() => {
          if (this.$refs.ocorrencia)
            this.$refs.ocorrencia.focus()
        });
      }
    },
    created() {
      this.id = this.$route.params.id;

      this.perfil = this.$auth.info().perfil;
      if (this.perfil == 'ADMIN')
        this.loadTransportador();

      if (this.id != null) {
        if (this.id === 'add')
          this.modelo = {};
        else
          this.load();
      }
    },
    mounted() {
      this.focus();
    }
  }
</script>